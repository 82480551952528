const usdcSymbol = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 154 148"
      fill="none"
      data-testid="usdc-icon-id"
      {...props}
    >
      <path
        d="M105 88.672C105 73.7139 96.0671 68.5852 78.2013 66.4487C65.4401 64.7388 62.8878 61.3201 62.8878 55.336C62.8878 49.3519 67.1419 45.5065 75.6491 45.5065C83.3058 45.5065 87.5599 48.0708 89.6865 54.4816C90.1122 55.7637 91.3883 56.6181 92.6645 56.6181H99.4698C101.172 56.6181 102.448 55.336 102.448 53.6271V53.1994C100.746 43.7966 93.0891 36.5314 83.3058 35.677V25.4198C83.3058 23.7099 82.0297 22.4277 79.9032 22H73.5225C71.8207 22 70.5446 23.2822 70.1189 25.4198V35.2493C57.3576 36.9591 49.2761 45.5065 49.2761 56.1914C49.2761 70.2951 57.7833 75.8505 75.6491 77.9881C87.5599 80.1246 91.3883 82.6889 91.3883 89.5274C91.3883 96.3659 85.4324 101.067 77.3509 101.067C66.2905 101.067 62.4621 96.3649 61.186 89.9541C60.7613 88.2453 59.4851 87.3898 58.209 87.3898H50.977C49.2761 87.3898 48 88.672 48 90.3819V90.8096C49.7008 101.494 56.5072 109.186 70.5446 111.324V121.581C70.5446 123.29 71.8207 124.572 73.9472 125H80.3279C82.0297 125 83.3058 123.718 83.7316 121.581V111.324C96.4928 109.186 105 100.211 105 88.672V88.672Z"
        fill="white"
      />
      <path
        d="M55.2424 133.884C21.9659 121.908 4.90078 84.694 17.2735 51.7568C23.6728 33.7914 37.7512 20.1038 55.2424 13.6876C56.9492 12.8324 57.8021 11.5492 57.8021 9.40976V3.42164C57.8021 1.7103 56.9492 0.427063 55.2424 0C54.8154 0 53.9625 -2.7413e-06 53.5355 0.427061C13.0069 13.2595 -9.1777 56.4627 3.62092 97.0984C11.3001 121.052 29.6451 139.445 53.5355 147.145C55.2424 148 56.9492 147.145 57.3751 145.434C57.8021 145.006 57.8021 144.578 57.8021 143.723V137.734C57.8021 136.451 56.5222 134.741 55.2424 133.884ZM100.464 0.427061C98.7577 -0.428093 97.0508 0.427062 96.6249 2.13839C96.1979 2.56648 96.1979 2.99355 96.1979 3.84973V9.83785C96.1979 11.5492 97.4778 13.2595 98.7577 14.1157C132.034 26.0919 149.099 63.306 136.727 96.2432C130.327 114.209 116.249 127.896 98.7577 134.312C97.0508 135.168 96.1979 136.451 96.1979 138.59V144.578C96.1979 146.29 97.0508 147.573 98.7577 148C99.1846 148 100.038 148 100.464 147.573C140.993 134.741 163.178 91.5373 150.379 50.9016C142.7 26.52 123.928 8.12652 100.464 0.427061V0.427061Z"
        fill="white"
      />
    </svg>
  )
}

export default usdcSymbol
