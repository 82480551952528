import { FiatToCryptoChangeRates } from '../types/FiatToCryptoChangeRates'
import {
  SupportedCoins,
  supportedCoinsData,
  supportedCoinsList,
} from '../types/SupportedCoins'

interface BtcToCurrencyRate {
  code: string
  name: string
  rate: number
}

const fetchBtcRates = async () => {
  const btcChangeRatesResponse = await fetch('https://bitpay.com/rates/BTC')
  const btcChangeRates = (await btcChangeRatesResponse.json()) as {
    data: BtcToCurrencyRate[]
  }

  return btcChangeRates.data
}

const computeUsdRateFromBtcRate = (
  btcToCurrencyRates: BtcToCurrencyRate[],
  coin: SupportedCoins
): number | null => {
  const btcToUsdRate = btcToCurrencyRates.find(
    (btcToCurrencyRate) => btcToCurrencyRate.code === 'USD'
  )?.rate
  if (btcToUsdRate === undefined) {
    return null
  }
  const btcToCoinRate = btcToCurrencyRates.find(
    (btcToCurrencyRate) =>
      btcToCurrencyRate.code === supportedCoinsData[coin].coin
  )?.rate

  return btcToCoinRate !== undefined ? btcToCoinRate / btcToUsdRate : null
}

export const fetchCoinsChangeRate = async () => {
  const btcToCurrencyRates = await fetchBtcRates()
  const fiatToCryptoChangeRates = supportedCoinsList.reduce(
    (changeRates: Partial<FiatToCryptoChangeRates>, coin: SupportedCoins) => ({
      ...changeRates,
      [coin]: computeUsdRateFromBtcRate(btcToCurrencyRates, coin),
    }),
    {}
  ) as FiatToCryptoChangeRates

  return fiatToCryptoChangeRates
}
