import Script from 'next/script'

const gtmId = process.env.NEXT_PUBLIC_GTM_ID

export const GTMAppScript = () => {
  return gtmId !== undefined ? (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');
        `,
        }}
      />
    </>
  ) : null
}

export const GTMDocumentScript = () => {
  return gtmId !== undefined ? (
    <>
      <noscript>
        <iframe
          title="GTM-DOCUMENT-SCRIPT"
          id="GTM-DOCUMENT-SCRIPT"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  ) : null
}
