const btcSymbol = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 107 148"
      fill="none"
      data-testid="btc-icon-id"
      {...props}
    >
      <path
        d="M98.7939 45.5319C97.2402 29.3767 83.295 23.9626 65.6728 22.4185V0H52.0364V21.8202C48.456 21.8202 44.7888 21.8877 41.1505 21.9649V0H27.5141L27.5044 22.3992C24.5513 22.4571 21.6465 22.515 18.8188 22.515V22.4475L0.00964832 22.4378V37.0103C0.00964832 37.0103 10.085 36.8173 9.91124 37.0007C15.4411 37.0007 17.2361 40.2047 17.7572 42.9745V68.5101C18.1433 68.5101 18.6355 68.5294 19.1952 68.6066H17.7572L17.7476 104.382C17.5063 106.119 16.4834 108.889 12.6231 108.898C12.7968 109.053 2.71184 108.898 2.71184 108.898L0 125.189H17.7572C21.0578 125.189 24.3101 125.246 27.4948 125.266L27.5044 147.935H41.1312V125.507C44.866 125.584 48.485 125.613 52.0268 125.613L52.0171 147.935H65.6535V125.314C88.5835 124.002 104.652 118.221 106.64 96.6805C108.252 79.3382 100.097 71.5887 87.078 68.4619C95.0012 64.4472 99.952 57.354 98.7939 45.5319ZM79.7049 93.9976C79.7049 110.935 50.7046 109.014 41.4496 109.014V78.9715C50.7046 78.9908 79.7049 76.3368 79.7049 93.9976ZM73.3548 51.6215C73.3548 67.0336 49.1509 65.2289 41.4496 65.2386V38.0044C49.1605 38.0044 73.3644 35.5531 73.3548 51.6215Z"
        fill="white"
      />
      <path d="M17.7812 67.916H19.8079V69.4119H17.7812V67.916Z" fill="white" />
    </svg>
  )
}

export default btcSymbol
