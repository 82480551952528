/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import Script from 'next/script'
import { FC } from 'react'

export const CookiesHeader: FC = () => {
  if (process.env.NEXT_PUBLIC_CURRENT_ENV === 'production') {
    return (
      <Script
        strategy="beforeInteractive"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="2d0ddcd9-b3da-4a2d-84f4-791d187d75e7"
      ></Script>
    )
  }

  return (
    <>
      <Script
        strategy="beforeInteractive"
        type="text/javascript"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        charSet="UTF-8"
        data-domain-script="503256a7-753e-42d4-b8e2-65f9dfbc7531"
      />
    </>
  )
}
