import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { FiltersFormData } from '@components/product/FiltersSidebarView/types'
import { FacetValue } from '@lib/api/categories/getCollectionFacets'
import { Category } from '@lib/api'

export interface FiltersContextValue {
  brandFacets?: FacetValue[]
  currentParentCollectionName?: string
  filters: FiltersFormData
  setFilters: (filters: FiltersFormData) => void
  resetFilters: () => void
}

export const FiltersContext = React.createContext<FiltersContextValue>({
  filters: {
    brandFacetIds: [],
  },
  setFilters: () => {},
  resetFilters: () => {},
})

interface FiltersContextProps {
  pageProps: {
    brandFacets?: FacetValue[]
    pageCategory?: Category
    pageCollectionSlug?: string
    currentCollectionSlug?: string
    filters?: FiltersFormData
  }
}

const baseFilters = {
  brandFacetIds: [],
}

export const FiltersContextProvider: FC<FiltersContextProps> = ({
  children,
  pageProps,
}) => {
  const [filters, setFilters] = React.useState<FiltersFormData>(baseFilters)
  const currentCollectionSlugRef = React.useRef<string>()
  const parentCollectionSlugRef = React.useRef<string>()
  const resetFilters = useCallback(() => {
    setFilters(baseFilters)
  }, [])

  useEffect(() => {
    const parentCollection =
      pageProps.pageCategory?.slug ?? parentCollectionSlugRef.current
    const parentSlugHasChanged =
      parentCollection !== parentCollectionSlugRef.current

    const currentCollection =
      pageProps.pageCollectionSlug ?? currentCollectionSlugRef.current
    const currentSlugHasChanged =
      currentCollection !== currentCollectionSlugRef.current

    if (parentSlugHasChanged || currentSlugHasChanged) {
      setFilters(baseFilters)
      parentCollectionSlugRef.current = parentCollection
      currentCollectionSlugRef.current = currentCollection
    }
  }, [pageProps, filters])

  const value = useMemo(
    () =>
      ({
        brandFacets: pageProps.brandFacets?.sort((brandA, brandB) =>
          brandA.name.localeCompare(brandB.name)
        ),
        currentParentCollectionName: pageProps.pageCategory?.slug,
        filters,
        setFilters,
        resetFilters,
      } as const),
    [filters, pageProps.brandFacets, pageProps.pageCategory?.slug]
  )

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}

export const useFilters = () => React.useContext(FiltersContext)
