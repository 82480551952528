const ethSymbol = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 94 148"
      fill="none"
      data-testid="eth-icon-id"
      {...props}
    >
      <path d="M0 74.0667L47 0L94 74.0667L47 101L0 74.0667Z" fill="white" />
      <path d="M47 0L94 74.0667L47 101V0Z" fill="white" />
      <path d="M0 84L47 110.947L94 84L47 148L0 84Z" fill="white" />
      <path
        d="M47 111.071L94 84.2143L47 148V111.071ZM0 74.1429L47 54L94 74.1429L47 101L0 74.1429Z"
        fill="white"
      />
      <path d="M47 54L94 74.1429L47 101V54Z" fill="white" />
    </svg>
  )
}

export default ethSymbol
