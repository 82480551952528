
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@assets/main.css'
import '@assets/chrome-bug.css'
import 'keen-slider/keen-slider.min.css'

import { FC, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { GTMAppScript, usePageViews } from '@lib/tracking'
import { CryptoCurrencyContextProvider } from '@lib/web3'
import { CryptoConverterContextProvider } from '@lib/web3'
import { FiltersContextProvider } from '@components/product/FiltersSidebarView/FiltersContext'
import { CookiesHeader } from '@components/common/Cookies/CookiesHeader'
import { WhitelistingContextProvider } from '@lib/web3/Whitelisting/WhitelistingContext'
import { useScrollRestoration } from '@lib/hooks/useScrollRestoration'

const Noop: FC = ({ children }) => <>{children}</>

const __Page_Next_Translate__ = function MyApp({ Component, pageProps, router }: AppProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const Layout = (Component as any).Layout || Noop

  // Enable GTM on page change
  usePageViews()

  useEffect(() => {
    document.body.classList.remove('loading')
  }, [])

  useScrollRestoration(router)

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <>
      <CookiesHeader />
      <GTMAppScript />
      <Head />
      <ManagedUIContext>
        <WhitelistingContextProvider>
          <CryptoCurrencyContextProvider>
            <CryptoConverterContextProvider>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <FiltersContextProvider pageProps={pageProps}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <Layout pageProps={pageProps}>
                  <Component {...pageProps} />
                </Layout>
              </FiltersContextProvider>
            </CryptoConverterContextProvider>
          </CryptoCurrencyContextProvider>
        </WhitelistingContextProvider>
      </ManagedUIContext>
    </>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  