import * as Sentry from '@sentry/nextjs'
import packageConfig from './package.json'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const CURRENT_ENV = process.env.NEXT_PUBLIC_CURRENT_ENV
const ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  release: packageConfig.version,
  environment: CURRENT_ENV,
  enabled: ENABLED,
})
