export const computeFiatToCoinConversionWithRate = (
  fiatValue: number, //in $ scents
  fiatToCoinChangeRate: number | null //
) => {
  if (fiatToCoinChangeRate === null) {
    return null
  }

  return (fiatValue / 100) * fiatToCoinChangeRate
}
