import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { sendEvent } from '@lib/tracking/events'

export const usePageViews = () => {
  const router = useRouter()

  const pageview = (url: string) => {
    sendEvent({
      event: 'pageview',
      data: { page: url },
    })
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)

    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])
}
