import React, { FC, useMemo, useState } from 'react'

type WhitelistingStatus = 'none' | 'whitelisted' | 'error'

export interface WhitelistingContextValue {
  whitelistingStatus: WhitelistingStatus
  setWhitelistingStatus: (whitelistingStatus: WhitelistingStatus) => void
}

export const WhitelistingContext =
  React.createContext<WhitelistingContextValue>({
    whitelistingStatus: 'none',
    setWhitelistingStatus: () => {},
  })

export const WhitelistingContextProvider: FC = ({ children }) => {
  const [whitelistingStatus, setWhitelistingStatus] =
    useState<WhitelistingStatus>('none')

  const value = useMemo(
    () => ({
      whitelistingStatus,
      setWhitelistingStatus,
    }),
    [whitelistingStatus, setWhitelistingStatus]
  )

  return (
    <WhitelistingContext.Provider value={value}>
      {children}
    </WhitelistingContext.Provider>
  )
}

export const useWhitelisting = () => React.useContext(WhitelistingContext)
