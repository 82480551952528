const dogeSymbol = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 200 200"
      fill="none"
      data-testid="doge-icon-id"
      {...props}
    >
      <path
        d="M101.888 65.5703H87.6719V93.5985H110.038V105.333H87.6719V133.36H102.585C106.417 133.36 134.043 133.792 134 100.752C133.957 67.7106 107.206 65.5703 101.888 65.5703Z"
        fill="white"
      />
      <path
        d="M99.5 0C44.5471 0 0 44.5471 0 99.5C0 154.453 44.5471 199 99.5 199C154.453 199 199 154.453 199 99.5C199 44.5471 154.453 0 99.5 0ZM103.409 153.24H67.3754V105.335H54.6733V93.5997H67.3744V45.6914H98.301C105.617 45.6914 154.077 44.172 154.077 100.365C154.077 157.487 103.41 153.24 103.41 153.24H103.409Z"
        fill="white"
      />
    </svg>
  )
}

export default dogeSymbol
