import { captureException } from '@sentry/nextjs'
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SupportedCoins, supportedCoinsList } from '../types/SupportedCoins'
import { computeFiatToCoinConversionWithRate } from '../helpers/computeFiatToCoinConversionWithRate'
import { FiatToCryptoChangeRates } from '../types/FiatToCryptoChangeRates'
import { fetchCoinsChangeRate } from '../helpers/fetchCoinsChangeRate'

interface CryptoConverterContextState {
  convertFiatToCoin: (fiatValue: number, coin: SupportedCoins) => number | null
  getSelectedCoinConversionRate: (coin: SupportedCoins) => number | null
}

const CRYPTO_RATE_UPDATE_INTERVAL = 90000

export const CryptoConverterContext =
  React.createContext<CryptoConverterContextState>({
    convertFiatToCoin: () => null,
    getSelectedCoinConversionRate: () => null,
  })

const EMPTY_CHANGE_RATES = Object.fromEntries(
  supportedCoinsList.map((coin) => [coin, null])
) as FiatToCryptoChangeRates

export const CryptoConverterContextProvider: FC = ({ children }) => {
  const [fiatToCryptoChangeRates, setFiatToCryptoChangeRates] =
    useState<FiatToCryptoChangeRates>(EMPTY_CHANGE_RATES)

  useEffect(() => {
    fetchCoinsChangeRate()
      .then((updatedRates) => setFiatToCryptoChangeRates(updatedRates))
      .catch((err) => {
        console.error(err)
        captureException(err)
      })

    const updateContentTimeout = setInterval(() => {
      fetchCoinsChangeRate()
        .then((updatedRates) => setFiatToCryptoChangeRates(updatedRates))
        .catch((err) => {
          captureException(err)
          console.error(err)
        })
    }, CRYPTO_RATE_UPDATE_INTERVAL)

    return () => clearInterval(updateContentTimeout)
  }, [])

  const convertFiatToCoin = useCallback(
    (fiatValue: number, coin: SupportedCoins) => {
      return computeFiatToCoinConversionWithRate(
        fiatValue,
        fiatToCryptoChangeRates[coin]
      )
    },
    [fiatToCryptoChangeRates]
  )

  const getSelectedCoinConversionRate = useCallback(
    (coin: SupportedCoins): number | null => fiatToCryptoChangeRates[coin],
    [fiatToCryptoChangeRates]
  )

  const value = useMemo(
    () => ({ convertFiatToCoin, getSelectedCoinConversionRate }),
    [convertFiatToCoin, getSelectedCoinConversionRate]
  )

  return (
    <CryptoConverterContext.Provider value={value}>
      {children}
    </CryptoConverterContext.Provider>
  )
}

export const useCryptoConverterContext = () =>
  useContext(CryptoConverterContext)
