const busdSymbol = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 148 148"
      fill="none"
      data-testid="busd-icon-id"
      {...props}
    >
      <path
        d="M73.5853 0L91.8158 18.7202L45.9102 64.75L27.6797 46.4702L73.5853 0Z"
        fill="white"
      />
      <path
        d="M101.26 27.75L119.491 46.4702L45.9102 120.25L27.6797 101.97L101.26 27.75Z"
        fill="white"
      />
      <path
        d="M18.2305 55.5L36.4609 74.2202L18.2305 92.5L0 74.2202L18.2305 55.5Z"
        fill="white"
      />
      <path
        d="M128.932 55.5L147.163 74.2202L73.582 148L55.3516 129.72L128.932 55.5Z"
        fill="white"
      />
    </svg>
  )
}

export default busdSymbol
