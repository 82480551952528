import { SupportedCoins } from '@lib/web3/types/SupportedCoins'
import { pushEcommerceEvent, pushEvent } from './pushEvent'

interface SelectWallet {
  event: 'Select_Wallet'
  data: {
    click_text: string
    button_id: string
  }
}

interface ConnectWallet {
  event: 'Connect_Wallet'
  data: {
    click_text: string
    wallet_name: string
  }
}

interface SignInWithWallet {
  event: 'Sign_In_With_Wallet'
  data: {
    click_text: string
    wallet_name: string
  }
}

interface ClickCategory {
  event: 'Click_Category'
  data: {
    click_url: string
    click_text: string
    page_path: string
    button_id: string
  }
}

interface ClickProduct {
  event: 'Click_Product'
  data: {
    page_path: string
    click_url: string
    product_image_url: string
    product_id: string
    product_brand: string
    product_crypto_price: string
    product_name: string
    product_fiat_price: string
    product_crypto_selected: string
  }
}

interface ClickHeader {
  event: 'Click_Header'
  data: {
    click_url: string
    click_text: string
    page_path: string
    button_id:
      | 'header_home'
      | 'header_currency'
      | 'header_wallet_connected'
      | 'header_wallet_not_connected'
      | 'header_cart'
      | 'header_burger'
  }
}

interface ClickFAQDetail {
  event: 'Click_Detail_FAQ'
  data: {
    question: string
    page_path: string
  }
}

interface ClickMoreQuestions {
  event: 'Click_More_Questions'
  data: {
    click_text: string
    page_path: string
  }
}

interface ClickInsideBurgerMenu {
  event: 'Click_Inside_Burger_Menu'
  data: {
    click_text: string
    page_path: string
    button_id: string
  }
}

interface ClickBanner {
  event: 'Click_Banner'
  data: {
    page_path: string
    click_text: string
    click_url: string
  }
}

interface SwipeProductSelection {
  event: 'Swipe_Product_Selection'
  data: {
    button_id: string
    product_category: string
  }
}

interface AddToCart {
  event: 'Add_To_Cart'
  data: {
    click_text: string
    product_sku: string
    product_crypto_selected: SupportedCoins
    product_crypto_price: string
    product_fiat_price: string
    size: string
    button_id: string
    product_name: string
    product_brand: string
    product_categories: string[]
    product_sub_categories: string[]
  }
}

interface ClickRemoveProduct {
  event: 'Click_Remove_Product'
  data: {
    product_id: string
    product_name: string
    button_id: string
    product_sku: string
  }
}

interface ClickProductInfo {
  event: 'Click_Product_Info'
  data: {
    page_path: string
    product_id: string
    product_name: string
  }
}

interface CheckoutStageReached {
  event: 'Checkout_Step_Reached'
  data: {
    stage:
      | 'shipping_address'
      | 'shipping_method'
      | 'payment_page'
      | 'payment_completed'
  }
}

interface BitpayPayment {
  event: 'Click_Bitpay_Payment'
  data: Record<string, never>
}

interface ClickBacktoWebsite {
  event: 'Click_Back_to_Website'
  data: {
    click_text: string
  }
}

interface SelectSortBy {
  event: 'Select_Sort_By'
  data: {
    page_path: string
    button_id: string
  }
}

interface ClickFooter {
  event: 'Click_Footer'
  data: {
    button_id: string
    click_text: string
    click_url: string
    page_path: string
  }
}

interface ClickSizeGuideButton {
  event: 'Click_Size_Guide'
  data: {
    page_path: string
    product_id: string
    product_name: string
  }
}

interface SelectCurrency {
  event: 'Select_Currency'
  data: {
    click_text: string
    page_path: string
    button_id: string
  }
}

interface PageView {
  event: 'pageview'
  data: {
    page: string
  }
}

interface ViewProductPage {
  event: 'View_Product_Page'
  data: {
    product_id: string
    product_name: string
    product_brand: string
    product_crypto_price: string
    product_fiat_price: string
    product_categories: string[]
    product_sub_categories: string[]
  }
}

interface ClickSubCategory {
  event: 'Click_Sub_Category'
  data: {
    page_path: string
    button_id: string
  }
}

interface ClickFilterBrand {
  event: 'Click_Filter_Brands'
  data: {
    click_text: string
    page_path: string
    brands_selected: string[]
    button_id: string
  }
}

interface ClickDownloadInvoice {
  event: 'Click_Download_Invoice'
  data: {
    click_text: string
  }
}

interface ClickViewOrder {
  event: 'Click_View_Order'
  data: {
    click_text: string
  }
}

interface InitiateCheckout {
  event: 'Initiate_Checkout'
  data: {
    wallet_status: string
    click_text: string
    order_crypto_selected: string
    order_crypto_price: string
    order_fiat_price: string
    order_products_crypto_prices: string[]
    order_products_brands: string[]
    order_products_fiat_prices: string[]
    order_products_skus: string[]
    order_products_names: string[]
    order_products_categories: string[][]
    order_products_sub_categories: string[][]
    button_id: string
    product_quantity: string
    nft_status: string
  }
}

interface ViewOrderPage {
  event: 'View_Order_Page'
  data: {
    order_crypto_selected: string
    order_crypto_price: string
    order_fiat_price: string
    order_products_crypto_prices: string[]
    order_products_fiat_prices: string[]
    order_products_brands: string[]
    order_products_skus: string[]
    order_products_names: string[]
    order_products_categories: string[][]
    order_products_sub_categories: string[][]
    nft_status: string
    product_quantity: string
  }
}

interface ViewAccessDeniedPage {
  event: 'View_Access_Denied_Page'
  data: Record<string, never>
}

interface ClickContactByEmail {
  event: 'Click_Contact_By_Email'
  data: Record<string, never>
}

interface ClickHomeNFTButton {
  event: 'Click_Home_NFT_Button'
  data: {
    click_text: string
    click_url: string
  }
}

interface ClickNFTInformation {
  event: 'Click_NFT_Information'
  data: Record<string, never>
}

interface ClickOrderSummaryNFTInformation {
  event: 'Click_Order_Summary_NFT_Information'
  data: Record<string, never>
}

interface ClickLandingHeader {
  event: 'Click_Landing_Header'
  data: {
    click_text: string
    button_id: string
    page_path: string
    access_denied: boolean
    click_url: string
  }
}

interface ClickLandingFooter {
  event: 'Click_Landing_Footer'
  data: {
    click_text: string
    button_id: string
    page_path: string
    access_denied: boolean
    click_url: string
  }
}

interface ClickLandingGetWhitelisted {
  event: 'Click_Landing_Get_Whitelisted'
  data: {
    click_text: string
    button_id: string
    page_path: string
    access_denied: boolean
    click_url: string
  }
}

interface Scroll25PercentLanding {
  event: 'Scroll_25%_Landing'
  data: Record<string, never>
}

interface Scroll50PercentLanding {
  event: 'Scroll_50%_Landing'
  data: Record<string, never>
}

interface Scroll75PercentLanding {
  event: 'Scroll_75%_Landing'
  data: Record<string, never>
}

interface Scroll100PercentLanding {
  event: 'Scroll_100%_Landing'
  data: Record<string, never>
}

interface Scroll25PercentHomepage {
  event: 'Scroll_25%_Homepage'
  data: Record<string, never>
}

interface Scroll50PercentHomepage {
  event: 'Scroll_50%_Homepage'
  data: Record<string, never>
}

interface Scroll75PercentHomepage {
  event: 'Scroll_75%_Homepage'
  data: Record<string, never>
}

interface Scroll100PercentHomepage {
  event: 'Scroll_100%_Homepage'
  data: Record<string, never>
}

interface ClickSizeDropdown {
  event: 'Click_Size_Dropdown'
  data: {
    page_path: string
    product_id: string
    product_name: string
  }
}

interface SelectSize {
  event: 'Select_Size'
  data: {
    page_path: string
    product_id: string
    product_name: string
    size: string
  }
}

interface ViewItem {
  event: 'view_item'
  currency: 'USD'
  value: number
  items: [
    {
      item_id: string
      item_name: string
      currency: 'USD'
      index: string
      item_brand: string
      item_category: string
      item_category2: string
      item_variant: string
      price: number
      quantity: 1
    }
  ]
}

interface ViewCart {
  event: 'view_cart'
  currency: 'USD'
  value: number
  items: {
    item_id: string
    item_name: string
    currency: 'USD'
    index: string
    item_brand: string
    item_category: string
    item_category2: string
    item_variant: string
    price: number
    quantity: number
  }[]
}

interface ClickZoomProduct {
  event: 'Click_Zoom_Product'
  data: {
    page_path: string
    product_id: string
    product_name: string
    product_brand: string
    product_image_url: string
  }
}

interface ClickProductZoomArrow {
  event: 'Click_Product_Zoom_Arrow'
  data: {
    product_brand: string
    product_name: string
    button_id: string
  }
}

interface ClickMyAccountLink {
  event: 'Click_My_Account_Link'
  data: {
    page_path: string
    click_url: string
  }
}

interface ResetFilters {
  event: 'Reset_Filters'
  data: {
    page_path: string
  }
}

interface SelectPaymentMethod {
  event: 'Select_Payment_Method'
  data: {
    button_id: 'Crypto' | 'Fiat'
  }
}

interface CopyPublicAddress {
  event: 'Copy_Public_Address'
  data: {
    page_path: string
  }
}

interface SubmitFiatPayment {
  event: 'Submit_Fiat_Payment'
  data: Record<string, never>
}

type Event =
  | SelectWallet
  | ClickCategory
  | ClickProduct
  | ClickHeader
  | ClickFAQDetail
  | ClickMoreQuestions
  | ClickInsideBurgerMenu
  | ClickBanner
  | SwipeProductSelection
  | AddToCart
  | ClickRemoveProduct
  | ClickProductInfo
  | CheckoutStageReached
  | BitpayPayment
  | ClickBacktoWebsite
  | SelectSortBy
  | ClickFooter
  | ClickSizeGuideButton
  | SelectCurrency
  | PageView
  | ViewProductPage
  | ClickSubCategory
  | ClickFilterBrand
  | ClickDownloadInvoice
  | ClickViewOrder
  | InitiateCheckout
  | ViewAccessDeniedPage
  | ClickContactByEmail
  | ClickNFTInformation
  | ClickHomeNFTButton
  | ClickLandingHeader
  | ClickLandingFooter
  | ClickLandingGetWhitelisted
  | ConnectWallet
  | SignInWithWallet
  | ClickOrderSummaryNFTInformation
  | ClickZoomProduct
  | ClickProductZoomArrow
  | ViewOrderPage
  | Scroll25PercentLanding
  | Scroll50PercentLanding
  | Scroll75PercentLanding
  | Scroll100PercentLanding
  | Scroll25PercentHomepage
  | Scroll50PercentHomepage
  | Scroll75PercentHomepage
  | Scroll100PercentHomepage
  | ClickSizeDropdown
  | SelectSize
  | ClickMyAccountLink
  | ResetFilters
  | SelectPaymentMethod
  | CopyPublicAddress
  | SubmitFiatPayment

type EcommerceEvent = ViewItem | ViewCart

/**
 * Send an event to GTM
 */
export const sendEvent = ({ event, data }: Event) => {
  if ('button_id' in data && typeof data.button_id === 'string') {
    data.button_id = data.button_id.toLowerCase().replaceAll(' ', '_')
  }
  pushEvent({
    event,
    ...data,
  })
}

export const sendEcommerceEvent = (event: EcommerceEvent) => {
  pushEcommerceEvent(event)
}
