import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isSupportedCoins } from '../helpers/isSupportedCoin'
import { SupportedCoins } from '../types/SupportedCoins'

interface CryptoCurrencyContextState {
  setSelectedCoin: (coin: SupportedCoins) => void
  selectedCoin: SupportedCoins
}

export const CryptoCurrencyContext =
  React.createContext<CryptoCurrencyContextState>({
    setSelectedCoin: () => {},
    selectedCoin: 'ETH',
  })

export const CryptoCurrencyContextProvider: FC = ({ children }) => {
  const [selectedCoin, setSelectedCoin] = useState<SupportedCoins>('ETH')

  useEffect(() => {
    const selectedCryptoCurrency = localStorage.getItem(
      'selectedCryptoCurrency'
    )
    if (isSupportedCoins(selectedCryptoCurrency)) {
      setSelectedCoin(selectedCryptoCurrency)
    }
  }, [])

  const updateSelectedCoin = useCallback(
    (coin: SupportedCoins) => {
      setSelectedCoin(coin)
      localStorage.setItem('selectedCryptoCurrency', coin)
    },
    [setSelectedCoin]
  )

  const value = useMemo(() => {
    return { setSelectedCoin: updateSelectedCoin, selectedCoin }
  }, [selectedCoin, updateSelectedCoin])

  return (
    <CryptoCurrencyContext.Provider value={value}>
      {children}
    </CryptoCurrencyContext.Provider>
  )
}

export const useCryptoCurrencyContext = () => useContext(CryptoCurrencyContext)
