interface GTMEvent {
  event: string
}

interface GTMEcommerceEvent extends GTMEvent {
  currency: 'USD'
  value: number
  items: Record<string, unknown>[]
}

export const pushEvent = (event: GTMEvent & Record<string, unknown>) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Sending GTM event', event)

    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (window === undefined) {
    throw 'window is undefined ! GTM push event is being called on the server...'
  }

  // @ts-expect-error We know GTM is initialized in the app
  if (window.dataLayer === undefined) {
    throw 'window does not have dataLayer property... Did you forget to add GTM ?'
  }

  // @ts-expect-error We know GTM is initialized in the app
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  window.dataLayer.push(event)
}

export const pushEcommerceEvent = (event: GTMEcommerceEvent) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Sending GTM ecommerce event', event)

    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (window === undefined) {
    throw 'window is undefined ! GTM push ecommerce event is being called on the server...'
  }

  // @ts-expect-error We know GTM is initialized in the app
  if (window.dataLayer === undefined) {
    throw 'window does not have dataLayer property... Did you forget to add GTM ?'
  }

  // @ts-expect-error We know GTM is initialized in the app
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  window.dataLayer.push(event)
}
