const litecoinSymbol = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 200 200"
      fill="none"
      data-testid="ltc-icon-id"
      {...props}
    >
      <g clipPath="url(#clip0_4407_2)">
        <path
          d="M98 0C78.6175 0 59.6702 5.7476 43.5542 16.516C27.4381 27.2844 14.8772 42.5899 7.45985 60.497C0.0424619 78.4042 -1.89826 98.1087 1.88309 117.119C5.66444 136.129 14.998 153.591 28.7036 167.296C42.4091 181.002 59.871 190.336 78.8812 194.117C97.8913 197.898 117.596 195.958 135.503 188.54C153.41 181.123 168.716 168.562 179.484 152.446C190.252 136.33 196 117.383 196 98C196.037 85.1678 193.547 72.454 188.671 60.5843C183.795 48.7146 176.629 37.9215 167.581 28.8214C158.534 19.7212 147.783 12.4922 135.942 7.54699C124.101 2.60181 111.402 0.0373389 98.5695 0H98ZM99.6611 101.322L89.4577 135.729H144.034C144.395 135.716 144.756 135.775 145.095 135.902C145.434 136.028 145.744 136.221 146.009 136.467C146.273 136.714 146.486 137.011 146.636 137.34C146.786 137.669 146.869 138.025 146.881 138.386V139.288L142.136 155.661C141.926 156.435 141.46 157.115 140.813 157.59C140.167 158.064 139.378 158.304 138.576 158.271H55.0509L69.0509 110.576L53.3899 115.322L56.9492 104.407L72.6102 99.661L92.3051 32.7458C92.5219 31.9758 92.9901 31.3006 93.6352 30.8276C94.2802 30.3545 95.0649 30.1109 95.8644 30.1356H116.983C117.345 30.1229 117.705 30.1816 118.044 30.3083C118.383 30.4351 118.693 30.6274 118.958 30.8742C119.222 31.121 119.435 31.4175 119.585 31.7468C119.735 32.0761 119.818 32.4317 119.831 32.7932V33.6949L103.22 90.1695L118.881 85.4237L115.559 96.8136L99.6611 101.322Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4407_2">
          <rect width="196" height="196" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default litecoinSymbol
