import { ReactNode } from 'react'
import btcSymbol from '@components/icons/btcSymbol'
import ethSymbol from '@components/icons/ethSymbol'
import usdcSymbol from '@components/icons/usdcSymbol'
import daiSymbol from '@components/icons/daiSymbol'
import busdSymbol from '@components/icons/busdSymbol'
import shibSymbol from '@components/icons/shibSymbol'
import apeSymbol from '@components/icons/apeSymbol'
import dogeSymbol from '@components/icons/dogeSymbol'
import litecoinSymbol from '@components/icons/litecoinSymbol'

export interface CoinData {
  isStable: boolean
  isPopular: boolean
  icon: ReactNode
  coin: string
  fullName: string
}

export const supportedCoinsData = {
  BTC: {
    isStable: false,
    isPopular: true,
    coin: 'BTC',
    fullName: 'Bitcoin',
    icon: btcSymbol,
  },
  ETH: {
    isStable: false,
    isPopular: true,
    coin: 'ETH',
    fullName: 'Ethereum',
    icon: ethSymbol,
  },
  LTC: {
    isStable: false,
    isPopular: false,
    coin: 'LTC',
    fullName: 'Litecoin',
    icon: litecoinSymbol,
  },
  USDC: {
    isStable: true,
    isPopular: false,
    coin: 'USDC',
    fullName: 'USD Coin',
    icon: usdcSymbol,
  },
  DAI: {
    isStable: true,
    isPopular: false,
    coin: 'DAI',
    fullName: 'Dai',
    icon: daiSymbol,
  },
  BUSD: {
    isStable: true,
    isPopular: false,
    coin: 'BUSD',
    fullName: 'Binance USD',
    icon: busdSymbol,
  },
  SHIB: {
    isStable: false,
    isPopular: false,
    coin: 'SHIB',
    fullName: 'Shiba Inu',
    icon: shibSymbol,
  },
  APE: {
    isStable: false,
    isPopular: false,
    coin: 'APE',
    fullName: 'ApeCoin',
    icon: apeSymbol,
  },
  DOGE: {
    isStable: false,
    isPopular: false,
    coin: 'DOGE',
    fullName: 'Dogecoin',
    icon: dogeSymbol,
  },
} as const

export type SupportedCoins = keyof typeof supportedCoinsData

export type SupportedCoinData = typeof supportedCoinsData[SupportedCoins]

export const supportedCoinsList = Object.keys(
  supportedCoinsData
) as SupportedCoins[]
