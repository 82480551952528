import { SupportedCoins, supportedCoinsList } from '../types/SupportedCoins'

export const isSupportedCoins = (
  coin: string | SupportedCoins | null
): coin is SupportedCoins => {
  return (
    coin !== null &&
    (supportedCoinsList as ReadonlyArray<string>).includes(coin)
  )
}
