import React, { FC, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'

const noop = () => {
  return
}

type SidebarCallback = () => void | Promise<void>

export interface State {
  displaySidebar: boolean
  displayDropdown: boolean
  sidebarView: string
  userAvatar: string
  message?: string
  messageVariables?: Record<string, string>
  openSidebar: () => void
  closeSidebar: () => void
  toggleSidebar: () => void
  closeSidebarIfPresent: () => void
  openDropdown: () => void
  closeDropdown: () => void
  setSidebarView: (
    view: SIDEBAR_VIEWS,
    sidebarSuccessCallback?: SidebarCallback,
    message?: string,
    messageVariables?: Record<string, string>
  ) => void
  setUserAvatar: (user: string) => void
  sidebarSuccessCallback: () => void
}

const initialState: State = {
  displaySidebar: false,
  displayDropdown: false,
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  message: undefined,
  messageVariables: undefined,
  openSidebar: noop,
  closeSidebar: noop,
  toggleSidebar: noop,
  closeSidebarIfPresent: noop,
  openDropdown: noop,
  closeDropdown: noop,
  setSidebarView: noop,
  setUserAvatar: noop,
  sidebarSuccessCallback: noop,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
      sidebarSuccessCallback?: SidebarCallback
      message?: string
      messageVariables?: Record<string, string>
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }

type SIDEBAR_VIEWS =
  | 'CART_VIEW'
  | 'WALLET_VIEW'
  | 'FILTERS_VIEW'
  | 'CHECKOUT_VIEW'
  | 'PAYMENT_METHOD_VIEW'
  | 'PAYMENT_VIEW'
  | 'SHIPPING_VIEW'
  | 'MOBILEMENU_VIEW'
  | 'CRYPTOCURRENCY_VIEW'
  | 'SHIPPING_INFO_VIEW'
  | 'SIZE_GUIDE_VIEW'
  | 'NFT_INFORMATION_VIEW'

export const UIContext = React.createContext<State>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
        message: undefined,
        messageVariables: undefined,
        sidebarSuccessCallback: noop,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
        sidebarSuccessCallback:
          action.sidebarSuccessCallback ?? state.sidebarSuccessCallback,
        message: action.message ?? state.message,
        messageVariables: action.messageVariables ?? state.messageVariables,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (
      view: SIDEBAR_VIEWS,
      sidebarSuccessCallback?: SidebarCallback,
      message?: string,
      messageVariables?: Record<string, string>
    ) =>
      dispatch({
        type: 'SET_SIDEBAR_VIEW',
        view,
        sidebarSuccessCallback,
        message,
        messageVariables,
      }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      setSidebarView,
      setUserAvatar,
    }),
    [
      closeDropdown,
      closeSidebar,
      closeSidebarIfPresent,
      openDropdown,
      openSidebar,
      setSidebarView,
      setUserAvatar,
      state,
      toggleSidebar,
    ]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)

  return context
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>
    <ThemeProvider defaultTheme="light">{children}</ThemeProvider>
  </UIProvider>
)
